import React, {Fragment} from "react";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {titleCase} from 'change-case';

const configSettings = {
	zeitApiToken: {type: 'text',password: true},
	useReleaseCandidates: {type: 'switch', on: '', off: 'false'}
};

export default class ToolsTab extends React.Component {

	state = {
		settings: this.props.settings || {}
	};
	handleChange = item => evt => {
		const wasSame = item.newVal === item.val;
		item.newVal = evt.target.value;
		const isSame = item.newVal === item.val;
		if (wasSame !== isSame) {this.props.onChange();}
		this.setState({settings: this.state.settings});
	}
	handleSwitchChange = (item, cfg) => evt => {
		const wasSame = item.newVal === item.val;
		const {on, off} = cfg;
		item.newVal = evt.target.checked ? on : off;
		const isSame = item.newVal === item.val;
		if (wasSame !== isSame) {this.props.onChange();}
		this.setState({settings: this.state.settings});
	}

	render() {
		const {settings} = this.state;
		return <Fragment>
			{Object.keys(settings).map(k => {
				const s = settings[k];
				const cfg = configSettings[k];
				return (cfg.type === 'text'
					&& <TextField key={k} fullWidth margin="normal" label={titleCase(k)}
						type={cfg.password ? 'password' : 'text'}
						value={s.newVal}
						onChange={this.handleChange(s)}
					/>
				) || (cfg.type === 'switch'
					&& <FormControlLabel key={k} label={titleCase(k)} style={{marginTop: '16px'}}
						control={
							<Switch color="primary"
								checked={s.newVal === cfg.on}
								onChange={this.handleSwitchChange(s, cfg)}
							/>
						}
					/>
				)
			})}
		</Fragment>;
	}
};
