import {request} from '../util/pipe';
import {storeGet} from '../util/store';
import React from "react";
import unfetch from "unfetch/dist/unfetch";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const WhiteSelect = withStyles(theme => ({
	root: {color: 'white'},
	icon: {color: 'white'}
}))(Select);

export default class EnvironmentSelector extends React.Component {
	state = {
		envName: 'loading...',
		newEnvName: null,
		envConfigs: {'loading...': 'Loading...'}
	};

	sortedEnvList = () => Object.keys(this.state.envConfigs).sort((a, b) => {
		const aCfg = this.state.envConfigs[a];
		const bCfg = this.state.envConfigs[b];
		if (!aCfg.product && bCfg.product) return -1;
		if (aCfg.product && !bCfg.product) return 1;
		return `${aCfg.product} ${aCfg.title}` > `${bCfg.product} ${bCfg.title}`;
	});

	handleEnvChange = evt => {
		const prevNewEnvName = this.state.newEnvName;
		const selectedEnv = evt.target.value;
		const newEnvName = (selectedEnv === this.state.envName ? null : selectedEnv);
		this.setState({newEnvName});
		if (prevNewEnvName !== newEnvName) {
			this.props.onChange(newEnvName);
		}
	};

	UNSAFE_componentWillMount() {
		request('baseUrl')
			.then(baseUrl => unfetch(`${baseUrl}/env-config-list.json`))
			.then(envConfigList => Promise.all([
				envConfigList.json(),
				storeGet('env').then(env => env.value)
			]))
			.then(([envConfigs, envName]) => {
				this.setState({envConfigs, envName: envName || 'production'});
			});
	}

	render() {
		const { envConfigs, envName, newEnvName } = this.state;
		return (
			<WhiteSelect value={newEnvName || envName} onChange={this.handleEnvChange}>
			{this.sortedEnvList().map(k => {
				const env = envConfigs[k];
				return <MenuItem key={k} value={k}>{`${env.product} ${env.title}`}</MenuItem>
			})}
			</WhiteSelect>
		);
	}
}
