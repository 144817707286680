
export const itemChanged = (item, val) => {
	item.newVal = val;
	if (item.newVal === item.val) {delete item.newVal;}
}

export const itemReset = item => {
	if (item.val !== item.origVal) {
		item.newVal = null;
	} else {
		delete item.newVal;
	}
}

export const sortByName = (a, b) => {
	if (a.name > b.name) return 1;
	if (a.name < b.name) return -1;
	return 0;
};

// TERMS
// "default value" (item.origVal) - the value used in production
// "override" (item.val)          - the value that has been overridden locally
// "entered value" (item.newVal)  - the override candidate that has not been saved yet

// ITEM STATES
// default item state - {origVal: 'a', val: 'a'}
// unsaved override - {origVal: 'a', val: 'a', newVal: 'b'}
// saved override - {origVal: 'a', val: 'b'}
// unsaved override clearing - {origVal: 'a', val: 'b', newVal: null}

export const inputVal = item => (
	item.newVal === null // if the override has been cleared
	? item.origVal       // use the default value
	: item.newVal        // otherwise use the entered value
) || item.val;         // if the entered value doesn't exist, use the current value

export const hasActiveReset = item => (
	// the entered value or the stored value is different from the default
	(item.newVal || item.val) !== item.origVal
	// and the user hasn't set this reset to be cleared already
	&& item.newVal !== null
);
