import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import indigo from '@material-ui/core/colors/indigo';
// import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {itemChanged, itemReset, inputVal, hasActiveReset} from '../util/tab-data-handlers';
import {titleCase} from 'change-case';


const styles = {
	subList: {
		margin: 0,
		padding: 0,
	},
	subHeader: {
		color: indigo[400],
		backgroundColor: 'white',
		outline: '4px solid white',
		marginBottom: '3px',
		marginTop: '5px',
		boxShadow: '0px 5px 4px',
		top: '74px'
	},
	expander: {width: '100%'}
};

export default class BuildConfigTab extends React.Component {

	state = {resettingValue: null};
	withUpdate = (fn, data) => { fn.apply(null, data); this.props.onChange() };
	handleChange = item => evt => this.withUpdate(itemChanged, [item, evt.target.value]);
	handleResetValue = item => evt => {
		evt.stopPropagation();
		this.withUpdate(itemReset, [item]);
	};

	render() {
		const tabData = this.props.tabData || [];
		const sections = tabData.reduce((acc, v, i, a) => {
			const vSplit = v.name.split('.');
			const section = vSplit.length > 1 ? vSplit[0] : 'General';
			if (section && !acc.includes(section)) {acc.push(section);}
			return acc;
		}, []);
		return <div style={{paddingTop: '5px'}}>
		<List subheader={<Paper elevation={1} />}>
		{
			sections.map(sectionId => (
				<li key={`section-${sectionId}`}>
				<ul style={styles.subList}>
				<ListSubheader style={styles.subHeader}>{titleCase(sectionId)}</ListSubheader>
				{tabData.filter(({name}) => (
					name.startsWith(`${sectionId}.`)
					|| (sectionId === 'General' && !name.includes('.'))
				)).map(v => {
					return (
					<ExpansionPanel key={`item-${v.name}`} style={styles.expander} elevation={1}>
						<ExpansionPanelSummary>
							<ListItemText secondary={(
								<React.Fragment>
									<Typography component="span" variant="body2" color="textPrimary">
									{v.name.replace(`${sectionId}.`, '')}
									</Typography>
									{` — ${inputVal(v)}`}
								</React.Fragment>
							)} />
							{hasActiveReset(v) ? (
								<ListItemSecondaryAction>
									<Tooltip title="Remove Override">
										<IconButton edge="end" onClick={this.handleResetValue(v)}>
											<RemoveCircleIcon />
										</IconButton>
									</Tooltip>
								</ListItemSecondaryAction>
							) : ""}
						</ExpansionPanelSummary>
						<ExpansionPanelDetails style={{paddingTop: 0}}>
							<TextField
								fullWidth margin="none" value={inputVal(v)}
								onChange={this.handleChange(v)}
							/>
						</ExpansionPanelDetails>
					</ExpansionPanel>);
				})}
				</ul>
				</li>
			))}
			</List>
			</div>;

	}
}
