import React, {useEffect, useState} from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
// Icons
import WebIcon from '@material-ui/icons/Web';
import BusinessIcon from '@material-ui/icons/Business';
import BuildIcon from '@material-ui/icons/Build';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';

import {searchForAssets} from './AutoDetectAssets.logic';

const groupIcon = {frameworks: <BusinessIcon />, uiModules: <WebIcon />, tools: <BuildIcon />};

export default function AutoDetectUiModules({assetData = {}, onChange = () => {}}) {
	const allData = () => Object.keys(assetData).reduce((acc, k) => acc.concat(assetData[k]), []);
	let data = allData();
	const [searching, setSearching] = useState(true);
	const [localServers, setLocalServers] = useState([]);
	const [overrides, setOverrides] = useState({});
	const [localInvalidOverrides, setLocalInvalidOverrides] = useState([]);
	const [cloudOverrides, setCloudOverrides] = useState([]);

	const updateOverrideLists = () => {
		const namedOverrides = data
			.filter(m => (m.newVal || m.val) !== m.origVal)
			.filter(m => m.newVal !== null)
			.reduce((acc, m) => Object.assign(acc, {[m.name]: (m.newVal || m.val)}), {})
		setOverrides(namedOverrides);
		const activeLocalServers = localServers
			.reduce((acc, s) => Object.assign(acc, {[s.name]: s.server}), {})
		setLocalInvalidOverrides(
			data
				.filter(m => (m.newVal || m.val) !== m.origVal)
				.filter(m => (m.newVal || m.val).includes('://localhost'))
				.filter(m => m.newVal !== null)
				.filter(m => activeLocalServers[m.name] !== (m.newVal || m.val))
		);
		setCloudOverrides(
			data
				.filter(m => (m.newVal || m.val) !== m.origVal)
				.filter(m => m.newVal !== null)
				.filter(m => !(m.newVal || m.val).includes('://localhost'))
		);
	};

	const handleEnableLocalOverride = (name, group, server) => (evt, isEnabled) => {
		let modData = data.find(m => m.name === name && m.group === group);
		if (modData == null) {
			modData = {name, group, origVal: '', val: ''};
			assetData[group].push(modData);
			data = allData();
		}
		if (isEnabled) {
			modData.newVal = modData.val === server ? undefined : server;
		} else {
			modData.newVal = modData.val === modData.origVal ? undefined : null;
		}
		updateOverrideLists();
		onChange();
	};

	const handleDeleteOverride = key => evt => {
		const modData = data.find(m => m.name === key);
		modData.newVal = null;
		updateOverrideLists();
		onChange();
	};

	useEffect(updateOverrideLists, [localServers]);

	useEffect(() => {
		if (searching !== true) {
			setTimeout(() => setSearching(true), 5000);
			return;
		}
		searchForAssets().then(res => {
			setLocalServers(res);
			setSearching(false);
		}).catch(err => console.error('searchForAssets ERROR', err));
	}, [searching]);

	return (
		<div style={{position: 'relative'}}>
			{searching && <LinearProgress style={{position: 'absolute', top: '10px', width: '100%'}} />}
			<List style={{opacity: searching && !localServers.length ? '0.3' : '1'}}>
			{localServers.length ? <ListSubheader>Local Assets Detected</ListSubheader> : ''}
			{localServers.map(s => (
				<ListItem key={s.server}>
					<ListItemIcon>{groupIcon[s.group]}</ListItemIcon>
					<ListItemText primary={`${s.group} › ${s.name}`} secondary={`PORT ${s.port}`} />
					<ListItemSecondaryAction>
						<Tooltip title={overrides[s.name] === s.server ? 'Active' : 'Inactive'}>
							<Switch
								edge="end"
								onChange={handleEnableLocalOverride(s.name, s.group, s.server)}
								checked={overrides[s.name] === s.server}
							/>
						</Tooltip>
					</ListItemSecondaryAction>
				</ListItem>
			))}
			{localInvalidOverrides.length ? <ListSubheader>Unreachable Assets</ListSubheader> : ''}
			{localInvalidOverrides.map(o => (
				<ListItem key={o.name + '-override'} style={{color: 'red'}}>
					<ListItemIcon>{groupIcon[o.group]}</ListItemIcon>
					<ListItemText primary={`${o.group} › ${o.name}`} secondary={`SERVER NOT FOUND: ${o.val}`} />
					<ListItemSecondaryAction>
						<Tooltip title="Remove Override">
							<IconButton onClick={handleDeleteOverride(o.name)}><DeleteIcon style={{color: 'red'}} /></IconButton>
						</Tooltip>
					</ListItemSecondaryAction>
				</ListItem>
			))}
			{cloudOverrides.length ? <ListSubheader>Cloud Deployment Overrides</ListSubheader> : ''}
			{cloudOverrides.map(o => (
				<ListItem key={o.name + '-override'}>
					<ListItemIcon><CloudQueueIcon /></ListItemIcon>
					<ListItemText primary={`${o.group} › ${o.name}`} secondary={o.newVal || o.val} />
					<ListItemSecondaryAction>
						<Tooltip title="Remove Override">
							<IconButton onClick={handleDeleteOverride(o.name)}><DeleteIcon /></IconButton>
						</Tooltip>
					</ListItemSecondaryAction>
				</ListItem>
			))}
			</List>
		</div>
	);
};
