import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import CloudIcon from "@material-ui/icons/Cloud";
import ZeitAssetPicker from './ZeitAssetPicker';
import {itemChanged, itemReset, sortByName, inputVal, hasActiveReset} from '../util/tab-data-handlers';
import glean from '../util/glean';

const cdnRoot = 'cdn1.frontlineeducation.com';
export default class AssetListTab extends React.Component {

	state = {assetTarget: null};
	withUpdate = (fn, data) => { fn.apply(null, data); this.props.onChange() };
	handleChange = item => evt => this.withUpdate(itemChanged, [item, evt.target.value]);
	handleResetValue = item => evt => {
		evt.stopPropagation();
		this.withUpdate(itemReset, [item]);
	};

	handleUrlSelection = deployUrl => {
		const {assetTarget} = this.state;
		assetTarget.newVal = deployUrl;
		this.setState({assetTarget: null});
		this.props.onChange();
	};

	render() {
		const {tabData=[], settings} = this.props;
		const {assetTarget} = this.state;
		const zeitApiToken = glean(settings, x => x.zeitApiToken.val);

		return <React.Fragment>
			{tabData.sort(sortByName).map(v => (
				<TextField
				label={v.name}
				fullWidth
				key={v.name}
				value={inputVal(v)}
				onChange={this.handleChange(v)}
				margin="normal"
				InputProps={{
					startAdornment: (hasActiveReset(v) ? (
						<InputAdornment position="start">
							<Tooltip title="Remove Override">
								<IconButton onClick={this.handleResetValue(v)}><RemoveCircle /></IconButton>
							</Tooltip>
						</InputAdornment>
					) : ""),
					endAdornment: (zeitApiToken && v.origVal.includes(cdnRoot) ? (
						<InputAdornment position="end">
							<Tooltip title="Choose Deployment">
								<IconButton onClick={() => this.setState({assetTarget: v})}><CloudIcon /></IconButton>
							</Tooltip>
						</InputAdornment>
					) : "")
				}}
			/>
			))}
			<ZeitAssetPicker
				projectProdUrl={!!assetTarget ? assetTarget.origVal : ''}
				apiToken={zeitApiToken}
				onSelection={this.handleUrlSelection}
				onClose={() => this.setState({assetTarget: null})}
			/>
		</React.Fragment>;
	}
};
