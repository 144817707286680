import {storeSet, storeGet} from './util/store';
import {send, request, ssEnvGet} from './util/pipe';
import processBuildConfig from './util/processBuildConfigData';
import glean from './util/glean';

import React from "react";
import ReactDOM from "react-dom";
import unfetch from "unfetch/dist/unfetch";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import MemoryIcon from '@material-ui/icons/Memory';
import AppsIcon from '@material-ui/icons/Apps';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import StormAppBar from './components/StormAppBar';
import BuildConfigTab from './components/BuildConfigTab';
import AssetListTab from './components/AssetListTab';
import SettingsTab from './components/SettingsTab';
import AutoDetectAssets from './components/AutoDetectAssets';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider, withStyles} from '@material-ui/styles';
import purple from '@material-ui/core/colors/purple';
import teal from '@material-ui/core/colors/teal';
import {titleCase} from 'change-case';
import "./styles.css";

const theme = createMuiTheme({palette: {primary: purple, secondary: teal}});
const SubTabs = withStyles({
	root: {
		backgroundColor: '#EEEEEE'
	},
	indicator: {
		backgroundColor: '#AAAAAA'
	}
})(Tabs);

const objToArr = (obj, group) => (Object.keys(obj)
	.reduce((acc, k, i, a) => acc.concat({
		name: k,
		val: obj[k],
		group,
		origVal: obj[k]
	}), [])
);
const styles = {
	buttonAction: {
		position: "fixed",
		right: "1rem",
		bottom: "1rem",
		zIndex: 2,
	},
	tabs: {
		position: 'sticky',
		top: 0,
		backgroundColor: 'white',
		zIndex: 2,
		boxShadow: '-3px 0 0 white, 3px 0 0 white',
		borderBottom: '1px solid #0001',
	},
	badge: {
		position: 'absolute',
		right: 0,
		top: 0,
		fontSize: '12px',
		lineHeight: '1.5',
		backgroundColor: '#999',
		borderRadius: '0 0 0 10px',
		color: 'white',
		width: '25px',
		height: '20px',
	}
};

const tabCount = (tabData=[]) => tabData
	.filter(v => (
		v.newVal !== null
		&& (v.newVal === undefined ? v.val : v.newVal) !== v.origVal)
	).length;

class App extends React.Component {
	state = {
		loading: true,
		assetTypes: [],
		tabValue: glean(window, x => x.localStorage.getItem('currentTab') || 'buildConfig', 'buildConfig'),
		subTab: 'frameworks',
		envFileName: null,
		unsavedChanges: false
	};
	withUpdate = (fn, data) => {
		fn.apply(this, data);
		this.checkForUnsavedChanges();
	};
	removeUnsavedChanges = () => {
		[...this.state.assetTypes, 'buildConfig']
			.reduce((acc, type) => acc.concat(this.state[type]), [])
			.forEach(v => delete v.newVal);
		this.forceUpdate();
	};
	hasUnsavedChanges = () => (
		[...this.state.assetTypes, 'buildConfig']
			.reduce((acc, type) => acc.concat(this.state[type]), [])
			.some(v => v.newVal !== undefined)
		|| Object.keys(this.state.settings)
			.some(k => {
				const setting = this.state.settings[k];
				return setting.val !== setting.newVal;
			})
		|| this.state.envFileName != null
	);
	checkForUnsavedChanges = () => setTimeout(() => this.setState({unsavedChanges: this.hasUnsavedChanges()}), 0);
	handleEnvChange = envFileName => this.withUpdate(this.setState, [{envFileName}]);
	handleTabChange = (evt, tabValue) => {
		const storage = glean(window, x => x.localStorage);
		if (storage != null) { storage.setItem('currentTab', tabValue); }
		this.setState({ tabValue, subTab: 'frameworks' });
	};
	handleCancel = () => {
		this.removeUnsavedChanges();
		this.setState({unsavedChanges: false});
		send('removeEnvConfig');
	};
	handleSave = () => {
		const {assetTypes, settings, buildConfig, envFileName} = this.state;
		const assetOverrides = Promise.all(
			assetTypes // SAVE ASSET OVERRIDES
				.reduce((acc, type) => acc.concat(this.state[type]), [])
				.filter(v => v.newVal !== undefined)
				.map(v => storeSet(`assets.${v.group}.${v.name}`, v.newVal))
		);

		const settingsData = Promise.all(
			Object.keys(settings)
				.map(key => Object.assign({key}, settings[key]))
				.filter(s => s.val !== s.newVal)
				.map(({key, newVal}) => storeSet(`storm.settings.${key}`, newVal || null))
		);

		if (envFileName != null) {storeSet('env', envFileName);}

		// reload the page when data is finished saving
		Promise.all([
			assetOverrides,
			settingsData
		]).then(() => {
			buildConfig // SAVE BUILD CONFIG OVERRIDES
				.filter(v => v.newVal !== undefined)
				.forEach(v => storeSet(`${v.group}.${v.name}`, v.newVal));

			this.removeUnsavedChanges();
			this.setState({unsavedChanges: false});

			send('reloadPage');
		}).catch(err => {
			console.error(err);
			alert('Unable to save. Please check the console or network tab in Dev Tools.');
		});
	};

	UNSAFE_componentWillMount() {
		const getSettingObject = k => storeGet(`storm.settings.${k}`).then(({value}) => ({key: k, value}));
		const settingKeys = ['useReleaseCandidates', 'zeitApiToken'];
		const loadSettings = Promise.all(settingKeys.map(getSettingObject))
			.then(settings => settings.reduce((acc, {key, value}) => Object.assign(acc, {
				[key]: {val: value || '', newVal: value || ''}
			}), {})
		);

		request('baseUrl')
		  .then(baseUrl => unfetch(`${baseUrl}/asset-manifest.json`))
		  .then(assetManifest => Promise.all([
				assetManifest.json(),
				request('buildConfigData'),
				ssEnvGet('manifest').then(o => o.value),
				loadSettings
			]))
		  .then(([assets, {buildConfig, buildConfigOrig}, manifest, settings]) => {
				const manifestData = Object.keys(manifest).reduce((acc, k) => {
					const o = manifest[k];
					if (o.path) {
						acc[k] = o.path.app.substr(0, o.path.app.lastIndexOf('/'));
					} else if (typeof o === 'object') {
						acc[k] = 'REMOVE';
					} else {
						acc[k] = `${buildConfig.moduleBasePath}/${manifest[k].long_name}/${manifest[k].version}`;
					}
					return acc;
				}, {});
				assets.uiModules = Object.assign({}, manifestData, assets.uiModules);
				const assetTypes = Object.keys(assets);
				this.setState({assetTypes});
				const baseObj = assetTypes.reduce((acc, type) => Object.assign(acc, {[type]: objToArr(assets[type], type)}), {});

				baseObj.settings = settings;
				baseObj.buildConfig = processBuildConfig(buildConfig, buildConfigOrig);

				const allAssetOverrides = assetTypes.reduce((acc, type) => acc.concat(
					Object.keys(assets[type]).map(name => storeGet(`assets.${type}.${name}`))
				), []);
				return Promise.all(allAssetOverrides)
					.then(res => res
						.filter(o => o.value != null)
						.reduce((base, override) => {
							const [, group, name] = override.key.split('.');
							const obj = base[group].find(cfg => cfg.name === name);
							obj.val = override.value;
							return base;
						}, baseObj)
					);
			})
			.then(newState => this.setState(Object.assign(newState, {loading: false})));
	}

	render() {
		const { loading, tabValue, subTab, assetTypes, unsavedChanges, settings } = this.state;

		let tabData;
		if (tabValue === 'assets') { tabData = assetTypes.reduce((acc, at) => Object.assign(acc, {[at]: this.state[at] || []}), {}); }
		if (tabValue === 'buildConfig') { tabData = this.state[tabValue]; }
		if (tabValue === 'advanced') { tabData = this.state[subTab]; }

		const assetTab = assetTypes.reduce((acc, tabName) => Object.assign(acc, {
			[tabName]: {
				active: subTab === tabName,
				count: tabCount(this.state[tabName])
			}
		}), {});
		const buildConfigCount = tabCount(this.state.buildConfig);
		const totalAssets = assetTypes.reduce((acc, at) => acc + assetTab[at].count, 0);
		return <ThemeProvider theme={theme}>{loading
			? <div style={{textAlign: 'center', paddingTop: '30px'}}><CircularProgress size={64} color="secondary" /></div>
			: <div>
			<StormAppBar onEnvChange={this.handleEnvChange} onChange={this.checkForUnsavedChanges} />
			{assetTypes.length && <Tabs value={tabValue} onChange={this.handleTabChange} style={styles.tabs} variant="fullWidth">
				<Tab value="buildConfig" icon={<MemoryIcon />} label={<div>
					Env Variables
					{buildConfigCount ? <span style={styles.badge}>{buildConfigCount}</span> : ''}
				</div>} />
				<Tab value="assets" icon={<SearchIcon />} label={<div>
					Detect Assets
					{totalAssets ? <span style={styles.badge}>{totalAssets}</span> : ''}
				</div>} />
				<Tab value="advanced" icon={<AppsIcon />} label="Configure Assets" />
				<Tab value="settings" icon={<SettingsIcon />} label="Settings" />
			</Tabs>}
			<form noValidate autoComplete="off">
			{tabValue === 'assets' && <AutoDetectAssets assetData={tabData} onChange={this.checkForUnsavedChanges} />}
			{tabValue === 'buildConfig' && <BuildConfigTab tabData={tabData} onChange={this.checkForUnsavedChanges} />}
			{tabValue === 'advanced' &&
				<div>
					<SubTabs value={subTab} onChange={(e, v) => this.setState({subTab: v})} centered>
					{assetTypes.map(at => (
						<Tab key={at} value={at} label={
							<div>
								{titleCase(at)}
								{assetTab[at].count ? <span style={styles.badge}>{assetTab[at].count}</span> : ''}
							</div>
						} />
					))}
					</SubTabs>
					{assetTypes.some(at => assetTab[at].active)
						&& <AssetListTab tabData={tabData} settings={settings} onChange={this.checkForUnsavedChanges} />
					}
				</div>
			}
			{tabValue === 'settings' && <SettingsTab settings={settings} onChange={this.checkForUnsavedChanges} />}
			</form>
			{unsavedChanges && (
			<Button onClick={this.handleSave} color="primary" variant="contained"
				style={Object.assign({}, styles.buttonAction, {right: "7rem"})}
			>Save & Reload</Button>
			)}
			<Button onClick={this.handleCancel} variant="contained"
				style={styles.buttonAction}
			>Cancel</Button>
		</div>
	}</ThemeProvider>;}
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
