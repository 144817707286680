import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import EnvironmentSelector from './EnvironmentSelector';
import {withStyles} from '@material-ui/core/styles';

const StyledAppBar = withStyles(theme => ({
	root: {
		position: 'relative',
		background: 'linear-gradient(#6d1b7bDD, #6d1b7bDD), url(https://i.imgur.com/4TllykK.jpg)',
		backgroundSize: 'cover',
		zIndex: 3,
	}
}))(AppBar);

export default class StormAppBar extends React.Component {

	render() {
		return <StyledAppBar position="static" style={{zIndex: 3}}>
			<Toolbar>
				<Typography variant="h6" style={{flexGrow: 1}}>
					Environment Control (Storm)
				</Typography>
				<EnvironmentSelector onChange={this.props.onEnvChange} />
			</Toolbar>
		</StyledAppBar>;
	}
};
