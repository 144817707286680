import unfetch from "unfetch/dist/unfetch";

const searchPorts = [3000, 3001, 3002, 3003, 3004, 3005, 3006, 3007, 3008, 3009, 3010, 3011, 3012];
export const searchForAssets = () => {
	const scans = [
		...searchPorts.map(port => scanLocalServer({group: 'uiModules', port, fileName: 'ui-module-config.json'})),
		scanLocalServer({group: 'uiModules', ssl: true, port: 3100, fileName: 'ui-module-config.json'}),
		...searchPorts.map(port => scanLocalServer({group: 'frameworks', ssl: true, port, fileName: 'framework.json'})),
		...searchPorts.map(port => scanLocalServer({group: null, port, fileName: 'asset.json'}))
	];
	console.groupCollapsed(`probing for localhost assets...`);
	return Promise.all(scans).then(res => {
		console.groupEnd(`probing for localhost assets...`);
		return res.filter(s => !!s);
	})
};

export const scanLocalServer = ({group, port, ssl = false, fileName, nameProp = 'name', timeout = 3000}) => {
	if ([group, port, fileName].some(x => typeof x === 'undefined')) {
		throw new Error(`scanLocalServer(config) => config requires "group", "port", and "fileName"`);
	}
	const server = `http${ssl ? 's' : ''}://localhost:${port}`;
	return Promise.race([
		unfetch(`${server}/${fileName}`),
		new Promise((_, reject) => setTimeout(reject, timeout))
	])
	.then(res => res.json())
	.then(json => ({
		group: group || json.group,
		name: json[nameProp],
		server, fileName, port, json
	}))
	.catch(err => false);
}
