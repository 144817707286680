import React from "react";
import Drawer from '@material-ui/core/Drawer';
import IconButton from "@material-ui/core/IconButton";
import CallMergeIcon from "@material-ui/icons/CallMerge";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import unfetch from 'unfetch';
import duration from 'humanize-duration';

const styles = {
	loadingDrawer: {
		width: '500px',
		textAlign: 'center',
		paddingTop: '30px'
	}
};

const cdnRoot = 'cdn1.frontlineeducation.com';
const teamQs = '?teamId=team_etamBCdc5c8dkjGsTTwKGknI'
const deploymentsApi = 'https://api.zeit.co/v4/now/deployments';
const aliasesApi = 'https://api.zeit.co/v2/now/aliases';
let zeitAssetProjectMapRequest;
let prodDeployments = [];

export default class ZeitAssetPicker extends React.Component {

	state = {
		deploymentsLoading: false,
		deploymentList: []
	};

	fetchDeployments = (projName) => {
		if (!projName) { return; }
		this.setState({deploymentsLoading: true});
		// const projectsApi = 'https://api.zeit.co/v1/projects';
		const headers = {Authorization: `Bearer ${this.props.apiToken}`};
		if (zeitAssetProjectMapRequest == null) {
			zeitAssetProjectMapRequest = unfetch(`${aliasesApi}${teamQs}`, {headers})
				.then(res => res.json())
				.then(({aliases}) => {
					prodDeployments = aliases
						.filter(a => a.alias.includes(cdnRoot))
						.map(a => a.deployment.id);

					return aliases.reduce((acc, a) => {
						if (a.alias.includes(cdnRoot)) {
							acc[`https://${a.alias}`] = a.projectId
						}
						return acc;
					}, {});
				});
		}
		zeitAssetProjectMapRequest
			.then(zeitAssetProjectMap => zeitAssetProjectMap[this.props.projectProdUrl])
			.then(projectId => unfetch(`${deploymentsApi}${teamQs}&projectId=${projectId}`, {headers}))
			.then(res => res.json())
			.then(({deployments}) => this.setState({
				deploymentsLoading: false,
				deploymentList: deployments.map(d => ({
					uid: d.uid,
					created: d.created,
					deployUrl: `https://${d.url}`,
					author: d.meta.githubCommitAuthorName,
					avatarUrl: `https://github.com/${d.meta.githubCommitAuthorLogin}.png?size=100`,
					branch: d.meta.githubCommitRef,
					commitMsg: d.meta.githubCommitMessage,
					commitLink: `https://github.com/${d.meta.githubOrg}/${d.meta.githubRepo}/commit/${d.meta.githubCommitSha}`,
				}))
			}));
		// setTimeout(() => this.setState({deploymentsLoading: false}), 2000);
	};

	getSnapshotBeforeUpdate(prevProps) {
		return {shouldFetchDeployments: prevProps.projectProdUrl !== this.props.projectProdUrl};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot.shouldFetchDeployments) {
			this.fetchDeployments(this.props.projectProdUrl);
		}
	}

	render() {
		const {deploymentsLoading, deploymentList} = this.state;
		const {projectProdUrl, onSelection} = this.props;
		return (
		<Drawer anchor="right" open={!!projectProdUrl} onClose={this.props.onClose}>
			{deploymentsLoading
			? <div style={styles.loadingDrawer}><CircularProgress size={64} color="secondary" /></div>
			: <List style={{width: '500px'}}>
				{deploymentList.map(d => (
					<ListItem key={d.uid} dense button
					 onClick={() => onSelection(d.deployUrl)}
					 style={{boxShadow: (prodDeployments.includes(d.uid) ? '0 0 5px #0003': 'none')}}
					>
						<ListItemAvatar>
							<Avatar src={d.avatarUrl}></Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={`${prodDeployments.includes(d.uid) ? '[PROD] ': ''}${d.branch} (${duration(Date.now() - d.created, {largest: 1, round: true})} ago)`}
							secondary={d.commitMsg}
						/>
						<ListItemSecondaryAction>
							<IconButton onClick={() => window.open(d.commitLink, '_blank')}><CallMergeIcon /></IconButton>
						</ListItemSecondaryAction>
					</ListItem>
					))}
				</List>
			}
		</Drawer>)
	}
};
